import React, { useEffect, useState } from "react";
import axios from "axios";
import successImage from "../Images/success.svg";
import failedImage from "../Images/faild.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BeatLoader, DotLoader } from "react-spinners";
import { make_accept } from "../../src/store/action/order/order";
import MoyasarPaymentForm from "../Components/PaymentMoyaserForm";
import { generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { payAgain_moyaser } from "../../src/store/action/cart/editCart";

function PaymentCheckMoyasar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;

  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const [payAgain, setPayAgain] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const language = useSelector((state) => state.language.lang);

  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get("status");
  const transactionId = searchParams.get("id");
  const amount = searchParams.get("amount")
    ? parseFloat(searchParams.get("amount")) / 100
    : null;
  const orderId = localStorage.getItem("tempOrderId");
  let history = useNavigate();

  const handleInvoice = () => {
    const id = `${localStorage.getItem("orderId")}-${localStorage
      .getItem("orderQueueId")
      .substring(1)}`;
    history(generatePath("/invoice/:id", { id }));
  };

  useEffect(() => {
    if (status === "paid" && transactionId) {
      const token = JSON.parse(localStorage.getItem("TOKEN"));

      const data = {
        transactionId: transactionId,
      };
      console.log(data);

      make_accept(token, JSON.parse(localStorage.getItem("orderId")), data)
        .then(() => setIsSuccess(true))
        .catch(() => {
          setIsSuccess(false);
          setErrorMsg("حدث فشل في عملية");
        })
        .finally(() => {
          setLoading(false);
          localStorage.removeItem("process");
        });
    } else {
      setLoading(false);
      setIsSuccess(false);
      setErrorMsg("حدث فشل في عملية");
      localStorage.removeItem("process");
    }
  }, []);

  return (
    <div className="container" style={{ height: "100vh" }}>
      {loading ? (
        <div className="loader">
          <DotLoader color={"#E47100"} size={50} />
        </div>
      ) : (
        <div className="orderRec" style={{ padding: "1em" }}>
          <div className="shareForm">
            {!isSuccess && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={failedImage}
                  alt="Failed"
                  style={{ marginBottom: "1em", width: "150px" }}
                />
                <p style={{ color: "rgb(99, 50, 53)", fontSize: "18px" }}>
                  حدث فشل في عملية
                </p>
              </div>
            )}
            {isSuccess && (
              <img
                src={successImage}
                alt="Success"
                style={{ marginBottom: "1em", width: "150px" }}
              />
            )}
            {isSuccess && (
              <div
                style={{ marginTop: "1em" }}
                className={
                  language == "ar" || language == "ur"
                    ? "orderBtns"
                    : "orderBtnsEn"
                }
              >
                <Link to="/menu/meals">
                  <button>{t("Basket.orderBtn")}</button>
                </Link>
                <button className="invoiceBtn" onClick={handleInvoice}>
                  {t("Basket.invoice")}
                </button>
              </div>
            )}
            {!isSuccess && (
              <>
                <Link to="/menu/meals">
                  <button>{t("Basket.orderBtn")}</button>
                </Link>
                <div
                  onClick={() => setPayAgain(true)}
                  style={{ marginTop: "10px" }}
                >
                  <button>{t("payment.pay_again")}</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {payAgain && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered "
            style={{
              marginTop: "120px",
              ...(isMobile && {
                width: "70%",
                marginLeft: "20%",
                marginRight: "20%",
              }),
            }}
          >
            <div
              className="modal-content"
              style={{ maxWidth: "600px", margin: "auto" }}
            >
              <div className="modal-body">
                <MoyasarPaymentForm
                  amount={amount}
                  onSubmit={() => Promise.resolve(true)}
                />
              </div>
              <div className="modal-footer "   style={{ display: "flex", justifyContent: "center" }}>
                <button
                
                  className="btn btn-secondary"
                  onClick={() => setPayAgain(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentCheckMoyasar;
