import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";

const MoyasarPaymentForm = forwardRef(({ amount, onSubmit }, ref) => {
  const formRef = useRef(null);
  const resolveRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const moyasarScript = document.createElement("script");
    moyasarScript.src = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.js";
    moyasarScript.async = true;
    document.body.appendChild(moyasarScript);

    const moyasarCss = document.createElement("link");
    moyasarCss.href = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.css";
    moyasarCss.rel = "stylesheet";
    document.head.appendChild(moyasarCss);

    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
    .mysr-form input {
      direction: ltr !important;
      text-align: left !important;
    }
  `;
    document.head.appendChild(styleTag);

    moyasarScript.onload = () => {
      if (window.Moyasar && amount) {
        window.Moyasar.init({
          element: ".mysr-form",
          amount: amount * 100,
          currency: "SAR",
          description: "Payment",
          language: "en",
          publishable_api_key: process.env.REACT_APP_MOYSER,
          callback_url: `${window.location.origin}/payment-check-moyasar`,
          methods: ["creditcard", "applepay"],
          apple_pay: {
            type: "applepay",
            label: "pay with apple",
            validate_merchant_url:
              "https://api.moyasar.com/v1/applepay/initiate",
            country: localStorage.getItem("countryId") == "1" ? "SA" : "BH",
          },
          fixed_width: false,
          on_loaded: () => {
            const formElement = document.querySelector(".mysr-form");
            const inputElements = document.querySelectorAll(".mysr-form input");
            inputElements.forEach((input) => {
              input.setAttribute("dir", "ltr");
              input.style.textAlign = "left";
            });

            const separator = document.querySelector(".mysr-methods-separator");
            if (separator) {
              separator.style.display = "none";
            }
          },
          on_initiating: () => {
            return new Promise(async (resolve, reject) => {
              resolveRef.current = resolve;
              try {
                const res = await onSubmit();
                if (!res) {
                  console.error("Order creation failed.");
                  reject("Order creation failed.");
                  return;
                }

                console.log("Order created, resolving Moyasar payment...");
                resolve(true);
              } catch (error) {
                console.error("Error creating order:", error);
                reject(error);
              }
            });
          },
        });

        if (formRef.current) {
          formRef.current.addEventListener("submit", (event) => {
            event.preventDefault();
          });
        }
      }
    };

    return () => {
      document.body.removeChild(moyasarScript);
      document.head.removeChild(moyasarCss);
    };
  }, [amount, onSubmit]);

  // Expose the formRef to parent via forwarded ref
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      const payButton = document.querySelector(".mysr-form-button");
      if (payButton) {
        payButton.click(); // Simulate a click on the Moyasar "Pay" button
      } else {
        console.error("Moyasar pay button not found.");
      }
    },
  }));

  return (
    <section
      style={{
        direction: "ltr",
        textAlign: "left",
      }}
    >
      <div className="mysr-form" ref={formRef}></div>
    </section>
  );
});

export default MoyasarPaymentForm;
