import axios from "axios";

export default axios.create({
  baseURL: `${process.env.REACT_APP_PROVIDER3}`,
  headers: {
    platform: "qr",

    "Content-type": "application/json",
  },
});
