import "../Styles/MainPage.css";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiTwotoneDelete } from "react-icons/ai";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { setLanguage } from "../store/action/language/language";
import { useDispatch, useSelector } from "react-redux";
import { fetchBasket } from "../store/action/cart/cart";
import { editQuantity } from "../store/action/cart/editCart";
import BeatLoader from "react-spinners/BeatLoader";

function BasketItem(props) {
  const [quan, setQuan] = useState(props.quantity);
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const bucket = useSelector((state) => state.cart.bucket);
  const { error, loading, cart } = useSelector((state) => state.cart);
  const totalPrice = props.unitPrice * quan;

  useEffect(() => {
    const language = languageState.lang;
    const direction = languageState.dir;

    i18n.changeLanguage(language);
    document.getElementById("root").style.direction = direction;
    dispatch(setLanguage(language, direction));

    if (language === "ar") {
      document.title = "مطاعم الرومانسية";
    } else {
      document.title = "Al Romansiah Restaurant";
    }
  }, [i18n, dispatch, loading]);

  function deleteItem() {
    props.setModal(true);
    props.setRemoveId(props.mealId);
  }

  function handleQuantity(productid, newQuantity) {
    const token = window.localStorage.getItem("TOKEN");

    if (newQuantity >= 1) {
      dispatch(editQuantity(productid, newQuantity))
        .then(() => {
          const token = JSON.parse(window.localStorage.getItem("TOKEN"));

          setQuan(newQuantity);
          props.setCalcTotal(!props.calcTotal);
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating quantity:", error);
        });
    }
  }

  return (
    <div className="col-12">
      <div className="bItem">
        <div className="row">
          <div className="col-8">
            <div>
              {props.isForCheckout ? null : (
                <AiTwotoneDelete
                  onClick={deleteItem}
                  className="pointer delBtn"
                  size={20}
                  style={{ marginTop: "8px" }}
                />
              )}
              <div>
                <p
                  className={
                    languageState.lang === "en" || languageState.lang === "id"
                      ? "enbName"
                      : "bName"
                  }
                >
                  {props.name}
                </p>
                <p
                  style={
                    languageState.lang === "en" || languageState.lang === "id"
                      ? { textAlign: "left" }
                      : null
                  }
                  className="bDetails"
                >
                  {props.description}
                </p>
              </div>
            </div>

            {props.isForCheckout ? (
              <div className="bBottom bFlex">
                <div>
                  
                  <p className="bQuan"> X {quan}</p>
                  
                </div>
                <p
                  style={
                    languageState.lang === "en" || languageState.lang === "id"
                      ? { direction: "ltr" }
                      : { direction: "rtl" }
                  }
                  className="bPrice"
                >
                  {totalPrice} {t("Currency")}
                </p>
              </div>
            ) : (
              <div className="bBottom bFlex">
                <div>
                  <button
                    onClick={() => handleQuantity(props.mealId, quan - 1)}
                    className="bBtn"
                  >
                    -
                  </button>
                  <p className="bQuan">{quan}</p>
                  <button
                    onClick={() => handleQuantity(props.mealId, quan + 1)}
                    className="bBtn"
                  >
                    +
                  </button>
                </div>
                <p
                  style={
                    languageState.lang === "en" || languageState.lang === "id"
                      ? { direction: "ltr" }
                      : { direction: "rtl" }
                  }
                  className="bPrice"
                >
                  {totalPrice} {t("Currency")}
                </p>
              </div>
            )}
          </div>
          <div className="col-4 bCenter">
            <BackgroundImage
              src={props.image}
              width="100%"
              className="basketItemImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasketItem;
