import * as actionType from "../cart/actionType";
import AxiosProvider from "../../../AxiosProvider";
import AxiosProvider2 from "../../../AxiosProvider2";
import { applicationId } from "../../../config";
import AxiosProvider3 from "../../../AxiosProvider3";

let num = 0;

export const deleteItem = (cartId, id, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionType.DELETE_ITEM_REQUEST,
      });

      let cart = JSON.parse(localStorage.getItem("cart"));
      const indexToRemove = cart.findIndex((item) => item.id === id);

      if (indexToRemove !== -1) {
        cart.splice(indexToRemove, 1);
        localStorage.setItem("cart", JSON.stringify(cart));
      } else {
        console.log("Product not found in cart.");
      }

      // await AxiosProvider.delete(`/cart/${cartId}/meal/${id}`, {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //     Accept: "application/json",
      //   },
      // });

      dispatch({
        type: actionType.DELETE_ITEM_SUCCESS,
        payload: { cartId, id },
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: actionType.DELETE_ITEM_FAILURE,
        error: e,
      });
    }
  };
};

export const clearcart = (cartId, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionType.CLEAR_CART_REQUEST,
      });

      localStorage.removeItem("cart");

      // await AxiosProvider.delete(`/cart/${cartId}`, {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //     Accept: "application/json",
      //   },
      // });

      dispatch({
        type: actionType.CLEAR_CART_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: actionType.CLEAR_CART_FAILURE,
        error: error,
      });
    }
  };
};

export const editQuantity = (productId, quantity) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionType.EDIT_QUANTITY_REQUEST });

      let products = JSON.parse(localStorage.getItem("cart")) || [];

      const productIndex = products.findIndex(
        (item) => item.productId === productId
      );

      if (productIndex !== -1) {
        products[productIndex].quantity = quantity;

        const cart = localStorage.setItem("cart", JSON.stringify(products));

        num++;

        dispatch({ type: actionType.EDIT_QUANTITY_SUCCESS, payload: num });
      } else {
        alert("Product not found.");
        dispatch({
          type: actionType.EDIT_QUANTITY_FAILURE,
          error: "Product not found.",
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: actionType.EDIT_QUANTITY_FAILURE, error: error });
    }
  };
};

export const check_offer_before_create = (data, token) => {
  const cart = JSON.parse(localStorage.getItem("cart"));
  const branchId = JSON.parse(localStorage.getItem("branchId"));
  const paymentMethod = process.env.REACT_APP_PAYMENT_METHOD;
  const products = cart;
  const platform = cart[0].platform;
  const notes = localStorage.getItem("notes");
  const phoneNumber = localStorage.getItem("phoneNumber");

  var body = {
    token: data.token || "",

    paymentMethod: paymentMethod,
    code: data.code,
    notes: notes,
    date: " ",
    now: "true",
    time: "",
    branchId: branchId,
    tableId: JSON.parse(localStorage.getItem("tableId")),
    typeId: JSON.parse(localStorage.getItem("typeId")),
    products: products,
    platform: platform,
    phone: phoneNumber,
    tablePrice: 0,
  };

  return AxiosProvider.post(`/offers/check-offers`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: applicationId,
    },
  });
};

export const check_offer = (data, token) => {
  const cart = JSON.parse(localStorage.getItem("cart"));
  const branchId = JSON.parse(localStorage.getItem("branchId"));
  const paymentMethod = process.env.REACT_APP_PAYMENT_METHOD;
  const products = cart;
  const platform = cart[0].platform;
  const notes = localStorage.getItem("notes");
  const phoneNumber = localStorage.getItem("phoneNumber");

  var body = {
    token: data.token || "",

    paymentMethod: paymentMethod,
    code: data.code,
    notes: notes,
    date: " ",
    now: "true",
    time: "",
    branchId: branchId,
    tableId: JSON.parse(localStorage.getItem("tableId")),
    typeId: JSON.parse(localStorage.getItem("typeId")),
    products: products,
    platform: platform,
    phone: phoneNumber,
    tablePrice: 0,
  };

  return AxiosProvider.post(`/orders`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: applicationId,
    },
  });
};

export const fetchBranchData = (branchId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionType.FETCH_BRANCH_REQUEST });

      const response = await AxiosProvider.get(`branches/${branchId}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          applicationId: applicationId,
        },
      });

      dispatch({
        type: actionType.FETCH_BRANCH_SUCCESS,
        payload: response.data.data,
      });
      localStorage.setItem("countryId", response.data.data.countryId);
      localStorage.setItem("currentBranch", JSON.stringify(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch({ type: actionType.FETCH_BRANCH_FAILURE, error: error });
    }
  };
};

export const Get_Token = (number) => {
  return AxiosProvider.post(`users/get-token?phone=${number}`);
};

export const payAgain_checkout = (token, paymentToken, orderId) => {
  var body = {
    token: paymentToken,
    applicationId: applicationId,
  };
  return AxiosProvider.post(`/orders/${orderId}/repay`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const payAgain_moyaser = (token, orderId) => {
  var body = {
    applicationId: applicationId,
  };
  return AxiosProvider.post(`/orders/${orderId}/payment-url`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
