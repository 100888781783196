import "../Styles/MainPage.css";
import "../Styles/checkout.css";

import BasketItem from "../Components/BasketItem";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { create_order } from "../store/action/order/order";
import { AiTwotoneDelete } from "react-icons/ai";
import { IoAddCircle } from "react-icons/io5";
import BeatLoader from "react-spinners/BeatLoader";
import OfferModal from "../Components/OfferModal";
import ConfirmDelete from "../Components/ConfirmDelete";
import RemoveMeal from "../Components/RemoveMeal";
import FreeMeal from "../Components/FreeMeal";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BackgroundImage } from "react-image-and-background-image-fade";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { setLanguage } from "../store/action/language/language";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRecommendedProducts,
  fetchPaymentMethod,
  fetchBasket,
} from "../store/action/cart/cart";
import {
  deleteItem,
  clearcart,
  check_offer,
  check_offer_before_create,
} from "../store/action/cart/editCart";

import MoyasarPaymentForm from "../../src/Components/PaymentMoyaserForm";

function CheckoutCart() {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [cartId, setCartId] = useState([]);
  const [total, setTotal] = useState();
  const [calcTotal, setCalcTotal] = useState(false);
  const [currency, setCurrency] = useState("currency");
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [discount, setDiscount] = useState(false);
  const [onlineType, setOnlineType] = useState("checkout");
  const [showPaymentSection, setShowPaymentSection] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState({
    paymentMethods: { paymentOnlineType: "checkout" },
  });
  const [cardValid, setCardValid] = useState(false);

  const [discountPrice, setDiscountPrice] = useState(false);
  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [modal, setModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeId, setRemoveId] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [freeMealModal, setFreeMealModal] = useState(false);
  const stateUpdate = useSelector((state) => state);
  const languageState = useSelector((state) => state.language);
  const [notes, setNotes] = useState("");
  const moyasarPaymentFormRef = useRef(null);

  const dispatch = useDispatch();
  const {
    recommendProducts: recommended,
    loading: loadRecommand,
    paymentMethodLoad,
    error,
  } = useSelector((state) => state.cart);
  // const paymentInfo = useSelector((state) => state.cart.paymentMethods);
  const bucket = useSelector((state) => state.cart.bucket);
  const topRef = useRef();
  const cart = useSelector((state) => state.cart.updateCart);

  const paymentRef = useRef();
  const codeRef = useRef();
  const noteRef = useRef();

  useEffect(() => {
    setProducts(bucket);
    const savedNotes = localStorage.getItem("notes");
    if (savedNotes) {
      setNotes(savedNotes);
    }
    check_offer_before_create_order().then((res) => {
      setShowPaymentSection(true);
    });
  }, []);

  const handleChange = () => {
    const newNotes = noteRef.current.value;
    setNotes(newNotes);
    localStorage.setItem("notes", newNotes);
  };

  useEffect(() => {
    const token = JSON.parse(window.localStorage.getItem("TOKEN"));

    const fetchBasketData = async () => {
      try {
        await dispatch(fetchBasket(token));
        setProducts(bucket);
      } catch (error) {
        console.error("Error fetching basket data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    const handleLanguage = () => {
      const data = window.localStorage.getItem("LANGUAGE");
      if (data !== null) {
        const { language, x } = JSON.parse(data);
        i18n.changeLanguage(language);
        document.getElementById("root").style.direction = x;
        dispatch(setLanguage(language, x));
      }
      document.title =
        i18n.language === "ar" ? "مطاعم الرومانسية" : "Al Romansiah Restaurant";
    };
    fetchBasketData();
    handleLanguage();
  }, [dispatch, cart, loadRecommand]);
  useEffect(() => {
    localStorage.removeItem("cardToken");
  }, []);

  const totalPrice = bucket.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  //in condition 1 if payment method is tap user is redirected to third party service else, user is redirected to /order-recieved

  async function checkOffer(cardToken) {
    const token = window.localStorage.getItem("TOKEN");
    setSubmitLoading(true);

    const typeId = window.localStorage.getItem("typeId");
    const tableId = window.localStorage.getItem("tableId");

    const data = {
      payment: selectedOption,
      code: codeRef.current?.value || "",
      typeId: typeId,
      token: cardToken || null, // Avoid storing an undefined token
    };
    const data2 = createData2(cardToken || null, typeId, tableId, data);

    window.localStorage.setItem("ORDER", JSON.stringify(data2));
    return await checkOfferAndCreateOrder(
      data2,
      token,
      data,
      handleCheckOfferError
    );
  }

  function handlePaymentMethodError() {
    setErrorMsgModel(true);
    setMsgEn("Please choose payment method");
    setMsgAr("برجاء اختيار طريقة الدفع");
    setSubmitLoading(false);

    setTimeout(function () {
      setErrorMsgModel(false);
    }, 2000);
  }

  function createData2(cardToken, typeId, tableId, data) {
    const baseData = {
      token: selectedOption === "checkout" ? cardToken : "",
      remainPaymentMethod: null,
      payment: selectedOption,
      code: localStorage.getItem("codes"),
      notes: localStorage.getItem("notes"),
    };

    if (typeId === 5) {
      return {
        ...baseData,
        tableId: tableId,
      };
    } else {
      return {
        ...baseData,
        tableId: "",
      };
    }
  }
  async function checkOfferAndCreateOrder(
    data2,
    token,
    data,
    handleCheckOfferError
  ) {
    localStorage.setItem("notes", "");

    check_offer(data, JSON.parse(token))
      .then(async (response) => {
        await handleOrderCreationResponse(response);

        // if (response.data.data.minPrice === null) {
        //   handleOfferResponse(response);
        // } else {
        //   handleMinPriceError(response);
        // }

        return response;
      })
      .catch((error) => {
        setErrorMsgModel(true);
        setMsgEn(error.response.data.error);
        setMsgAr(error.response.data.error);
        setSubmitLoading(false);

        setTimeout(function () {
          setErrorMsgModel(false);
        }, 2000);
      });
    return "redirect";
  }

  async function checkOfferAndCreateOrder(
    data2,
    token,
    data,
    handleCheckOfferError
  ) {
    localStorage.setItem("notes", "");

    try {
      const response = await check_offer(data, JSON.parse(token)); // ✅ Wait for response

      await handleOrderCreationResponse(response); // ✅ Wait for redirection

      return response; // ✅ Return response so `checkOffer` can use it
    } catch (error) {
      setErrorMsgModel(true);
      setMsgEn(error.response?.data?.error || "Unknown error");
      setMsgAr(error.response?.data?.error || "خطأ غير معروف");
      setSubmitLoading(false);

      setTimeout(() => {
        setErrorMsgModel(false);
      }, 2000);

      throw error; // ✅ Propagate error to caller
    }
  }

  function handleCheckOfferError(error) {
    // Handle the error here
    setLoading(false);
    setSubmitLoading(false);
    setErrorMsgModel(true);
    setMsgEn(error.response.data.error);
    setTimeout(() => {
      setErrorMsgModel(false);
    }, 5000);
  }

  function handleOrderCreationResponse(response) {
    console.log("here is the res");
    console.log(response.data.data);

    localStorage.removeItem("cardToken");
    localStorage.setItem("orderId", response.data.data.orderId);
    localStorage.setItem("orderQueueId", response.data.data.orderQueueId);
    localStorage.removeItem("notes");
    localStorage.removeItem("codes");

    //to remove
    const paymentMethod = process.env.REACT_APP_PAYMENT_METHOD;

    if (paymentMethod != "moyasar")
      window.location.href = response.data.data.url;

    localStorage.removeItem("cart");
  }

  function handleOrderCreationError(error) {
    if (error.response.status === 422) {
      setErrorMsgModel(true);
      setMsgEn(error.response.data.error);
      setMsgAr(error.response.data.error);
      setSubmitLoading(false);

      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (
      error.response.data.error ===
      "Transaction has been failed,please try again"
    ) {
      localStorage.removeItem("cardToken");
      localStorage.setItem("orderId", error.response.data.data.orderId);
      setErrorMsgModel(true);
      setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
      setMsgEn("Transaction has been failed,please try again");
      setSubmitLoading(false);

      setTimeout(function () {
        history("/failed-payment");
      }, 2000);
    }
  }

  const handleSubmitTriggerMoyaser = () => {
    if (moyasarPaymentFormRef.current) {
      moyasarPaymentFormRef.current.submitForm(); // Trigger the form submission
    }
  };

  async function handleOfferResponse(response) {
    if (
      response.data.data.offerType === "discount" &&
      response.data.data.freeMeals.length !== 0
    ) {
      window.localStorage.setItem("offerType", "freeMealsDiscount");
      window.localStorage.setItem(
        "FREEMEALS",
        JSON.stringify(response.data.data.freeMeals)
      );
      setSubmitLoading(false);
      setDiscount(true);
      setModal(true);
      setDiscountPrice(response.data.data.totalPrice);
    } else if (
      response.data.data.offerType === "cashBack" &&
      response.data.data.freeMeals.length !== 0
    ) {
      window.localStorage.setItem("offerType", "freeMealsCashBack");
      window.localStorage.setItem(
        "FREEMEALS",
        JSON.stringify(response.data.data.freeMeals)
      );
      setSubmitLoading(false);
      setFreeMealModal(true);
    } else if (
      response.data.data.offerType === "discount" &&
      response.data.data.cashBack == true
    ) {
      setSubmitLoading(false);
      window.localStorage.setItem("offerType", "discountCashBack");
      setDiscount(true);
      setModal(true);
      setDiscountPrice(response.data.data.totalPrice);
    } else if (response.data.data.offerType === "freeMeals") {
      setSubmitLoading(false);
      window.localStorage.setItem(
        "FREEMEALS",
        JSON.stringify(response.data.data.freeMeals)
      );
      localStorage.setItem("offerType", "freeMeal");
      setFreeMealModal(true);
    } else if (response.data.data.offerType === "cashBack") {
      setSubmitLoading(false);
      localStorage.setItem("offerType", "cashBack");
      // setFreeMealModal(true);
    } else if (response.data.data.offerType === "discount") {
      setSubmitLoading(false);
      setDiscount(true);
      setModal(true);
      setDiscountPrice(response.data.data.totalPrice);

      setTotal(response.data.data.price);
      localStorage.setItem("offerType", "discount");
    } else {
    }
  }

  function handleMinPriceError(response) {
    setErrorMsgModel(true);
    setMsgEn(
      "Order total price must be at least " + response.data.data.minPrice + " "
    );
    setMsgAr("الحد الأدنى للطلب " + response.data.data.minPrice + " ");
    setSubmitLoading(false);

    setTimeout(function () {
      setErrorMsgModel(false);
    }, 2000);
  }

  //on clicking cleart cart and confirimg in ConfirmDelete modal
  //Clear_cart api call takes cart id and user token
  function clearCart() {
    const token = window.localStorage.getItem("TOKEN");
    dispatch(clearcart(cartId, JSON.parse(token)));
    if (!error) {
      localStorage.removeItem("cardToken");
      setProducts([]);
      localStorage.setItem("notes", "");
      setLoading(false);
      history("/menu/meals");
    }
  }

  const check_offer_before_create_order = async (cardToken) => {
    const token = JSON.parse(window.localStorage.getItem("TOKEN"));
    setSubmitLoading(true);

    const typeId = window.localStorage.getItem("typeId");
    const tableId = window.localStorage.getItem("tableId");

    const data = {
      payment: selectedOption,
      code: codeRef.current?.value || "",
      typeId: typeId,
      token: cardToken || null,
      userId: JSON.parse(localStorage.getItem("userId")),
    };
    return await check_offer_before_create(data, token)
      .then((res) => {
        return handleOfferResponse(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  async function onSubmit() {
    try {
      console.log("Starting order creation...");

      const orderResponse = await checkOffer();
      if (!orderResponse) {
        console.error("Order response is empty or undefined");
        return null;
      }

      console.log("Order created successfully:", orderResponse);
      return orderResponse;
    } catch (error) {
      console.error("Error in onSubmit:", error);
      return "test"; // Ensure function returns something
    }
  }

  //on clicking delete button in BasketItem component and confirming delete in RemoveMeal modal
  //Delete_Item api call takes cart id, meal id and and user token
  //meal id was sotred when user clicks on  delete button in BasketItem component
  function handleClose() {
    const token = window.localStorage.getItem("TOKEN");
    dispatch(deleteItem(cartId, removeId, JSON.parse(token)));
    setLoading(false);
    if (!error) {
      setProducts(products.filter((data) => data.id != removeId)); //set product list array to all items except that of deleted id
      setCalcTotal(!calcTotal);
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="Basket ">
        {loading ? (
          <div className="loader">
            <BeatLoader color={"#E47100"} loading={loading} size={30} />
          </div>
        ) : (
          <div>
            {modal ? (
              <OfferModal
                setModal={setModal}
                checkOffer={checkOffer}
                totalPrice={discountPrice}
                price={total}
                onHandleContinue={() => setModal(false)}
              />
            ) : null}
            {freeMealModal ? (
              <FreeMeal
                setModal={setFreeMealModal}
                totalPrice={discountPrice}
                price={total}
              />
            ) : null}

            {delModal ? (
              <ConfirmDelete
                setLoading={setLoading}
                setModal={setDelModal}
                clearCart={clearCart}
              />
            ) : null}

            {removeModal ? (
              <RemoveMeal
                setLoading={setLoading}
                handleCls={handleClose}
                setModal={setRemoveModal}
              />
            ) : null}
            <div
              className="  container"
              style={
                products.length == 0 || recommended.length == 0
                  ? null
                  : { overflow: "hidden" }
              }
            >
              <div ref={topRef} className="bTop">
                <div></div>
                {!products.length == 0 ? (
                  <p
                    style={
                      i18n.language == "ar" || i18n.language == "ur"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {" "}
                    {t("Basket.title")}
                  </p>
                ) : null}

                <Link to="/menu/meals">
                  {" "}
                  <button
                    style={{ width: "50px", height: "50px" }}
                    className={
                      languageState.lang == "en" || languageState.lang == "id"
                        ? "ciBackbtn bck"
                        : "bck"
                    }
                  >
                    <HiArrowRight size={25} />
                  </button>
                </Link>
              </div>

              {products.length == 0 || recommended.length == 0 ? null : (
                <div className="spRecomend">
                  <p> {t("Single_product.recommended")}</p>
                  <Swiper
                    navigation={true}
                    dir={
                      i18n.language == "ar" || i18n.language == "ur"
                        ? "rtl"
                        : "ltr"
                    }
                    key={i18n.language}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    modules={[Navigation]}
                    className={
                      i18n.language == "ar" || i18n.language == "ur"
                        ? "recommendSlider recommmendAr"
                        : "recommendSlider recommmendEn"
                    }
                  >
                    {recommended &&
                      recommended.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div
                            style={{
                              margin: "0.5em 0",
                              paddingBottom: "1em",
                              cursor: "pointer",
                            }}
                            onClick={() => history(`/meals/${data.id}`)}
                          >
                            <div className=" medyafMenu reccomendation recommendBasket">
                              <BackgroundImage
                                src={data.image}
                                width="100%"
                                style={{
                                  borderRadius: "8px 8px 0 0",
                                  maxHeight: "160px",
                                }}
                                className="bckg"
                              />

                              <div style={{ padding: "0.3em" }}>
                                <p className="recommend-name">
                                  {" "}
                                  {data.name && data.name}
                                </p>
                                <p className="recommend-price">
                                  {data.price} {t("Currency")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              )}
            </div>

            <div className="  container">
              <div className="row">
                {products &&
                  products.map((data, index) => {
                    return (
                      <BasketItem
                        isForCheckout={true}
                        setModal={setRemoveModal}
                        setRemoveId={setRemoveId}
                        setLoading={setLoading}
                        key={index}
                        cartId={cartId}
                        mealId={data.productId}
                        unitPrice={data.price}
                        name={data != null ? data.name : "not found"}
                        image={data.image}
                        description={data.description ?? "nout found"}
                        quantity={data.quantity}
                        calcTotal={calcTotal}
                        setCalcTotal={setCalcTotal}
                        currency={t("Currency")}
                      />
                    );
                  })}
                {/* products.map((data)=>{
       <BasketItem handleCls={handleClose}/>
       <BasketItem quan={10} subtract={handleSub} add={handleAdd}/>
     })*/}
              </div>
              <div className="bDiv2">
                {!products.length == 0 ? (
                  <div
                    className={
                      languageState.lang == "en" || languageState.lang == "id"
                        ? " enbNote"
                        : "bNote"
                    }
                  >
                    <form style={{ position: "relative" }}>
                      {/*<input type="text" placeholder='....اضف ملاحظاتك هنا &#160; &#xf4ad;'></input>
                       */}

                      <div className="paymentTYpe">
                        <p> {t("Basket.payment")}</p>
                        <div
                          style={
                            i18n.language == "en" || languageState.lang == "id"
                              ? { direction: "rtl" }
                              : null
                          }
                          className="row"
                        >
                          <div
                            style={{ justifyContent: "end" }}
                            className="col-sm  tapChoice"
                          >
                            <label>{t("Basket.credit")}</label>
                            {onlineType == "tap" ? (
                              <input
                                type="radio"
                                value="tap"
                                checked
                                name="payment"
                                id="tap"
                              ></input>
                            ) : (
                              <input
                                type="radio"
                                value="checkout"
                                checked
                                name="payment"
                                id="tap"
                              ></input>
                            )}
                          </div>
                        </div>

                        <div></div>
                        {showPaymentSection &&
                        process.env.REACT_APP_PAYMENT_METHOD == "moyasar" ? (
                          <MoyasarPaymentForm
                            amount={discountPrice || totalPrice}
                            onSubmit={onSubmit}
                          />
                        ) : (
                          <div
                            className="creditCard"
                            style={
                              onlineType == "checkout"
                                ? { marginTop: "1.5em", direction: "rtl" }
                                : { display: "none" }
                            }
                          >
                            <Frames
                              cardValidationChanged={(e) => {
                                setCardValid(e.isValid);
                              }}
                              config={{
                                publicKey: `${process.env.REACT_APP_PAYMENT}`,
                                schemeChoice: true,
                              }}
                              cardTokenized={(e) => {
                                checkOffer(e.token);
                              }}
                            >
                              <CardNumber />
                              <div className="date-and-code">
                                <ExpiryDate />
                                <Cvv />
                              </div>

                              {submitLoading ? (
                                <div
                                  style={{
                                    height: "6vh",
                                    marginTop: "2em",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                  className="loader"
                                >
                                  <BeatLoader
                                    color={"#E47100"}
                                    loading={submitLoading}
                                    size={30}
                                  />
                                  <p className="paymentLoad">
                                    {t("payment.processing")}
                                  </p>
                                </div>
                              ) : (
                                <button
                                  disabled={!cardValid}
                                  id="pay-button"
                                  onClick={() => {
                                    setSubmitLoading(true);
                                    if (localStorage.getItem("cardToken")) {
                                      checkOffer(
                                        localStorage.getItem("cardToken")
                                      );
                                    } else {
                                      Frames.submitCard();
                                    }
                                  }}
                                >
                                  {t("Basket.button")}
                                </button>
                              )}
                            </Frames>

                            <p>
                              {t("Invoice.total_price")} :{" "}
                              <span
                                style={{ color: "#e47200", margin: "0 0.3em" }}
                              >
                                {totalPrice} {t("Currency")}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          errorMsgModel
                            ? "addAlert errAlert"
                            : " addAlert alert-hidden errAlert"
                        }
                      >
                        <div className="row">
                          <div className="col">
                            <div
                              className={
                                i18n.language == "ar" || i18n.language == "ur"
                                  ? "vAlignText"
                                  : "vAlignText enAlert"
                              }
                            >
                              <span className="err">
                                {languageState.lang == "en" ? msgEn : msgAr}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="bEmpty">
                    <p>{t("Basket.empty")}</p>
                    <Link to="/menu/meals">
                      <button>
                        {t("Basket.add")}&#160;
                        <IoAddCircle />
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div
              className="partition"
              style={
                onlineType == "checkout" && products.length !== 0
                  ? { height: "150px" }
                  : { height: "300px" }
              }
            ></div>

            <div
              style={
                products.length == 0 || onlineType == "checkout"
                  ? { bottom: "80px" }
                  : null
              }
              className={
                submitLoading && onlineType == "tap"
                  ? "bBottomnv bBottomnv2"
                  : "bBottomnv"
              }
            >
              <div>
                <div
                  className={
                    (!products.length == 0 || submitLoading) &&
                    onlineType == "tap"
                      ? "bBottomNavT bBottomNavMobile"
                      : " bBottomNavT "
                  }
                  style={
                    (!products.length == 0 || submitLoading) &&
                    onlineType == "tap"
                      ? null
                      : { display: "none", paddingBottom: "0" }
                  }
                >
                  {submitLoading ? (
                    <div className="loaderDiv">
                      <BeatLoader
                        color={"#E47100"}
                        loading={submitLoading}
                        size={30}
                      />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-4 align">
                        <p
                          style={
                            languageState.lang == "en" ||
                            languageState.lang == "id"
                              ? { direction: "ltr" }
                              : { direction: "rtl" }
                          }
                        >
                          {" "}
                          {total} {t("Currency")}{" "}
                        </p>
                      </div>
                      <div className="col-8 alignBtn">
                        <button onClick={() => checkOffer()}>
                          {t("Basket.button")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className={products.length==0?'bBottomNav bBottomNavEmpty ':'bBottomNavEmpty bBottomNav'}>
               */}{" "}
              <div
                className={
                  products.length == 0 || onlineType == "checkout"
                    ? "bBottomNav bBottomNavEmpty "
                    : "bBottomNav"
                }
              >
                <NavLink to="/cart">
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_356_6018)">
                        <g clipPath="url(#clip1_356_6018)">
                          <path
                            d="M6.17761 10.6667H27.7035L28.4757 7.61905H9.26642V4.57143H30.4556C30.6904 4.57143 30.9221 4.62425 31.133 4.72585C31.344 4.82745 31.5287 4.97516 31.6731 5.15777C31.8176 5.34038 31.9179 5.55307 31.9665 5.77967C32.0151 6.00628 32.0108 6.24084 31.9537 6.46552L28.0927 21.7036C28.009 22.0331 27.8162 22.3256 27.5448 22.5346C27.2733 22.7436 26.9389 22.8571 26.5946 22.8571H4.63321C4.22361 22.8571 3.83078 22.6966 3.54115 22.4108C3.25152 22.1251 3.08881 21.7375 3.08881 21.3333V3.04762H0V0H4.63321C5.04281 0 5.43564 0.160544 5.72527 0.446313C6.0149 0.732083 6.17761 1.11967 6.17761 1.52381V10.6667ZM6.17761 32C5.35841 32 4.57276 31.6789 3.9935 31.1074C3.41423 30.5358 3.08881 29.7607 3.08881 28.9524C3.08881 28.1441 3.41423 27.3689 3.9935 26.7974C4.57276 26.2259 5.35841 25.9048 6.17761 25.9048C6.99682 25.9048 7.78247 26.2259 8.36173 26.7974C8.94099 27.3689 9.26642 28.1441 9.26642 28.9524C9.26642 29.7607 8.94099 30.5358 8.36173 31.1074C7.78247 31.6789 6.99682 32 6.17761 32ZM24.7105 32C23.8913 32 23.1056 31.6789 22.5263 31.1074C21.9471 30.5358 21.6216 29.7607 21.6216 28.9524C21.6216 28.1441 21.9471 27.3689 22.5263 26.7974C23.1056 26.2259 23.8913 25.9048 24.7105 25.9048C25.5297 25.9048 26.3153 26.2259 26.8946 26.7974C27.4738 27.3689 27.7993 28.1441 27.7993 28.9524C27.7993 29.7607 27.4738 30.5358 26.8946 31.1074C26.3153 31.6789 25.5297 32 24.7105 32Z"
                            fill="#BDBDBD"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_356_6018">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_356_6018">
                          <rect
                            width="35.5556"
                            height="35.5556"
                            fill="white"
                            transform="translate(-1.77778 -1.77777)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <p> {t("Bottom_Nav.basket")}</p>
                  </div>{" "}
                </NavLink>

                <NavLink to="/menu/meals">
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0001 9.375C14.4492 9.375 13.1876 10.6366 13.1876 12.1875C13.1876 12.6087 13.2872 13.0048 13.4538 13.3638C7.21573 14.5118 2.35167 19.9141 1.92261 26.375H30.0776C29.6485 19.9141 24.7845 14.5118 18.5464 13.3638C18.713 13.0048 18.8126 12.6087 18.8126 12.1875C18.8126 10.6366 17.551 9.375 16.0001 9.375Z"
                        fill="#BDBDBD"
                      />
                      <path
                        d="M21.625 1.875H30.0625V0H21.625C20.0741 0 18.8125 1.26163 18.8125 2.8125C18.8125 4.36337 20.0741 5.625 21.625 5.625H25.375C25.8923 5.625 26.3125 6.04525 26.3125 6.5625C26.3125 7.07975 25.8923 7.5 25.375 7.5H23.5C21.9491 7.5 20.6875 8.76163 20.6875 10.3125V11.25H22.5625V10.3125C22.5625 9.79525 22.9827 9.375 23.5 9.375H25.375C26.9259 9.375 28.1875 8.11337 28.1875 6.5625C28.1875 5.01163 26.9259 3.75 25.375 3.75H21.625C21.1077 3.75 20.6875 3.32975 20.6875 2.8125C20.6875 2.29525 21.1077 1.875 21.625 1.875Z"
                        fill="#BDBDBD"
                      />
                      <path
                        d="M0 30.125H3.1705L4.108 32H27.892L28.8295 30.125H32V28.25H0V30.125Z"
                        fill="#BDBDBD"
                      />
                    </svg>
                    <p> {t("Bottom_Nav.menu")}</p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default CheckoutCart;
